<template>
    <div> 
        <ul v-if="show">
            <li><img :src='require("@/assets/css/客服.png")'> <i>在线客服</i>
            <div class="no" style='height:400Px;overflow-y:auto'>
                <div class="kefu">
                    <div class="one">
                        <img :src='require("@/assets/css/头像.png")'>
                        <div class="S">
                            <img :src='require("@/assets/css/三角.png" )'>
                        </div>
                        <p>
                            您好，山西瑞海节能网控电热集中供暖有限公司欢迎您！瑞海热控是一家集研发、实施、安装、运行、售后服务于一体的高新技术企业，依托自主研发的互联网云控制系统，为客户提供智能化、一体化冷暖服务。您好，欢迎来到瑞海热控！请您选择想要咨询的内容！
                        </p>
                    </div>
                    
                    <div class="two">
                        <img :src='require("@/assets/css/头像.png")' alt="">
                        <div class="S">
                            <img :src='require("@/assets/css/三角.png")' alt="">
                        </div>
                        <p>
                            联系电话：<br>
                            0351-2334100<br>
                            <a href="tencent://message/?uin=547875977&Menu=yes& Service=300&sigT=42a1e5347953b64c5ff3980f8a6e644d4b31456cb0b6ac6b27663a3c4dd0f4aa14a543b1716f9d45">联系人工客服QQ</a>
                        </p>
                    </div>

                    <div class="san">
                        <img :src='require("@/assets/css/头像.png")' alt="">
                        <div class="S">
                            <img :src='require("@/assets/css/三角.png")' alt="">
                        </div>
                        <p>
                            <i>热门问题</i>
                            <a href="/energy-paltform">智慧控制系统</a>
                            <b>冷暖一体化服务</b>
                            <span>
                                <a href="/gas-boiler">燃气锅炉一体化解决方案</a>
                                <a href="/electric-tracing">电采暖一体化解决方案</a>
                                <a href="/air-source">空调整体服务</a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            </li>
            <li><img :src='require("@/assets/css/电话.png")'> <i>客服电话</i>
            <div class="none">
                <p>0351-2334100</p>
            </div>
            </li>
            <li><img :src='require("@/assets/css/微信.png")'> <i>官方微信</i>
            <div class="non">
                <img :src='require("@/assets/css/footer/gongzhonghao.jpg" )'>
            </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
    return {
	show:false,
    }
  },
  methods: {
	handleScroll() {
      let scrollTop = document.documentElement.scrollTop
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= 500) {
        this.show = true
      } else {
        this.show = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  // 组件销毁前
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="less" scoped>
ul{
   position:fixed;
   top: 250Px;
   right: 20Px;
   z-index: 99999;
}
li{
    background-color: #f56a00;
    border-radius: 10Px;
    padding: 10Px;
    box-sizing: border-box;
    cursor: pointer;
    width: 80Px;
    height: 80Px;
    text-align: center;
    margin-top: 10Px;
    img{
        width: 60%;
    }
    i{
        display: block;
        font-size: 14Px;
        color: #fff;
        font-style: normal;
    }
}
ul li .none , .non, .no{
    display: none;
}
ul li:hover .none{
    display: block;
    position:fixed;
    margin-top: -52PX;
    right: 115Px;
    background-color: #ccc;
    color: #fff;
    padding:0 15Px;
}
ul li:hover .non{
    display: block;
    position:fixed;
    margin-top: -75PX;
    right: 90Px;
    width: 150Px;
}
ul li:hover .no{
    display: block;
    position:fixed;
    margin-top: -75PX;
    right: 0Px;
    width: 280Px;
    background-color: #f2f2f2;
}
.kefu img{
    width: 30Px;
    height: 30Px;
}
.kefu{
    padding: 10Px;
    padding-right: 20Px;
    box-sizing: border-box;
}
.one{
    display: flex;
    
    p{
        float: left;
        margin-left: -15Px;
        background-color: #fff;
        font-size: 14Px;
        padding: 15Px;
        border-radius: 20px;
        box-sizing: border-box;
        text-align-last: left;
    }
    img{
        margin-top: 95Px;
        margin-right: 10Px;
        display: block;
    }
    .S img{
        float: left;
        display: block;
        margin-top: 20Px;
        width: 20Px;
        height: 20Px;
        margin-top: 100Px;

    }
}

.two{
    display: flex;
    p{
        float: left;
        margin-left: -15Px;
        background-color: #fff;
        font-size: 14Px;
        padding: 15Px;
        border-radius: 20px;
        box-sizing: border-box;
        text-align-last: left;
        a{
            color: #000;
        }
        a:hover{
            color: #f56a00;
        }
    }
    img{
        margin-top: 35Px;
        margin-right: 10Px;
        display: block;
    }
    .S img{
        float: left;
        display: block;
        margin-top: 20Px;
        width: 20Px;
        height: 20Px;
        margin-top: 40Px;

    }
}
.san{
    display: flex;
    p{
        float: left;
        margin-left: -15Px;
        background-color: #fff;
        font-size: 14Px;
        padding: 15Px;
        border-radius: 20px;
        box-sizing: border-box;
        text-align-last: left;
        a{
            color: #000;
        }
        i{
            display: block;
            color: #000;
        }
    }
    img{
        margin-top: 35Px;
        margin-right: 10Px;
        display: block;
    }
    .S img{
        float: left;
        display: block;
        margin-top: 20Px;
        width: 20Px;
        height: 20Px;
        margin-top: 40Px;

    }
    b{
        font-weight: normal;
        display: block;
    }
    span a{
        font-size: 12Px;
        display: block;
        margin-left: 8Px;
    }
     a:hover{
        color: #f56a00;
    }
}

</style>